import axios from "axios";
import {setAuthorizationHeader} from "../../api/config";

const GET_ABONNEMENT_CLIENT = `${process.env.REACT_APP_API_URL}/client/abonnement-client`
const GET_CLIENT_DETAILS = `${process.env.REACT_APP_API_URL}/client/client-details`
const GET_CLIENT_LISTE_MODELE_FILE = `${process.env.REACT_APP_API_URL}/client/liste-modele-files`
const GET_DASHBOARD_CLIENT = `${process.env.REACT_APP_API_URL}/client/dashboard-details`

const FILE_MODELE_LETTRE_CLIENT = `${process.env.REACT_APP_API_URL}/client/modele-lettre/ajouter`
export const DOWNLOAD_FILE_MODELE_SOMMAIRE = `${process.env.REACT_APP_API_URL}/client/file/download-modele-sommaire`
const GET_LISTE_MODELE_LETTRE = `${process.env.REACT_APP_API_URL}/client/liste-modele-lettre`

export const addFileModeleLM = async (data) => {
    setAuthorizationHeader()
    return axios
        .post(FILE_MODELE_LETTRE_CLIENT, data)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => {
            console.log(err);
            return {
                status: 'error',
                message: 'Une erreur est survenue lors de l\'envoi du fichier !',
            };
        });
}


export const getClientAbonnement = async (client_id) => {
    setAuthorizationHeader()
    return axios.get(`${GET_ABONNEMENT_CLIENT}/${client_id}`).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getClientBogDetails = async (client_id) => {
    setAuthorizationHeader()
    return axios.get(`${GET_CLIENT_DETAILS}/${client_id}`).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getClientBogListeModeleFile = async (client_id) => {
    setAuthorizationHeader()
    return axios.get(`${GET_CLIENT_LISTE_MODELE_FILE}/${client_id}`).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getListeModeLM = async (client_bo) => {
    setAuthorizationHeader()
    return axios.get(`${GET_LISTE_MODELE_LETTRE}/${client_bo}`).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getClientDashboard = async () => {
    setAuthorizationHeader()
    return axios
        .get(GET_DASHBOARD_CLIENT)
        .then((res) => {
            if (res && res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

// export const getModeleSommaireFile = async (file_id) => {
//     setAuthorizationHeader()
//     return axios
//         .get(`${DOWNLOAD_FILE_MODELE_SOMMAIRE}/${file_id}`)
//         .then((res) => {
//             if (res && res.status === 200) {
//                 return res.data
//             } else {
//                 return {
//                     status: 'error',
//                     message: 'La connexion à internet est instable !',
//                 }
//             }
//         })
//         .catch((err) => console.log(err))
// }


export const getModeleSommaireFile = async (file_id) => {
    setAuthorizationHeader()
    return axios
        .get(
            `${DOWNLOAD_FILE_MODELE_SOMMAIRE}/${file_id}`,
            {responseType: 'blob'},
        )
        .then((res) => {
            if (res && res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}