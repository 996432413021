import React, {Fragment, useEffect, useRef, useState} from 'react';
import ClientModalMesures from "./EB1/EB1_3";
import ClientModalEntite from "./EB1/EB1_1";
import {
    addReponseAccepation,
    addReponseQuestion,
    getListeReponseMission,
    updateReponseQuestion
} from "../../../../../data/exercice";
import FileUploadQuestionnaire from "./EB1/FileUploadQuestionnaire";
import swal from "sweetalert";
import {succesMessage} from "../../../../../helpers/AssetsHelpers";
import {Button, notification, Space, Steps, Tooltip} from "antd";
import {getListeQuestion} from "../../../../../data/params/question";
import {ajouterUserIdentification} from "../../../../../data/rateau";
import ReactToPrint from "react-to-print";
import docx from "html-docx-js/dist/html-docx";
import fileSaver from "file-saver";
import {PrintHeader} from "../../../../layout/tools/PrintHeader";
import {
    faBriefcase,
    faFileCircleCheck,
    faFileCircleQuestion, faFilePdf, faFileWord,
    faIdCard,
    faListCheck, faLock, faPrint,
    faUserTag
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {StepBody, StepHeader} from "../../../../common/Step.components";
import {Spinner} from "@chakra-ui/react";
import {appTheme} from "../../../../../config/theme";
import ClientModalAffectation from "./EB1/EB1_4";
import PageChildLabel from "../../../../common/PageLabel/PageChildLabel";
import {AcceptationPrint} from "./EB1/AcceptationPrint";
import UserCycleIdentification from "../../UserCycleIdentification";
import {useLocation, useNavigate} from "react-router-dom";

const { Step } = Steps;

const AcceptationMission = ({
        mission,
        client,
        code,
        modalId,
        prev
    }) => {
    const navigate = useNavigate();
    // const location = useLocation();
    const [activeStep, setActiveStep] = useState(0);
    const [loading, setLoading] = useState(false);
    const [mesure_sauve, setMesureSauve] = useState(mission?.mesure_sauve);
    const [selectedElementsListEntity, setSelectedElementsListEntity] = useState([]);
    const [selectedElementsListAudit, setSelectedElementsListAudit] = useState([]);
    const [isFileAdd, setIsFileAdd] = useState(false)
    const [questionsEntity, setQuestionsEntity] = useState([]);
    const [questionsAuditeur, setQuestionsAuditeur] = useState([]);
    const [listQuestionAdd, setListQuestionAdd] = useState(false);
    const [codeSommaire] = useState(code || "EB1")
    const [tache_id, setTache_id] = useState(null);
    const [user_id, setUser_id] = useState(null);
    const [date, setDate] = useState('');
    const [heureEntree, setHeureEntree] = useState('');
    const [intervention_id, setIntervention_id] = useState(null);
    // const [isModalOpen, setIsModalOpen] = useState(true);
    // const [identLoading, setIdentLoading] = useState(false);
    const [acceptValid] = useState((modalId === 2) || false);
    const [affectValid, setAffectValid] = useState(false);
    const [observation_generale, setObservation_generale] = useState("");
    const [associe_responsable, setAssocieResponsable] = useState("");
    const [second_associer, setSecondAssocier] = useState("");
    const [etat, setEtat] = useState(0);
    const [selectedIds, setSelectedIds] = useState([]);
    const [isTaskSave, setIsTaskSave] = useState([]);
    const [isToggleOpen, setIsToggleOpen] = useState(false);
    const [auth, setAuth] = useState(false);
    // const [authId, setAuthId] = useState(null);
    const componentRef = useRef();
    const [api, contextHolder] = notification.useNotification();

    const openNotificationWithIcon = () => {
        api['warning']({
            message: 'Attention! Cycle verrouillé.',
            description:
                'Veuillez vous identifier !',
        });
    };

    useEffect(() => {
        setAffectValid(mission?.etat === 2 && (mission && mission?.lettre_mission === 1))
        setIsFileAdd(mission?.fichier_question === 1)
        setAssocieResponsable(mission.associe_responsable)
        setSecondAssocier(mission.second_associer)
    }, [mission])

    const exportToWord = () => {
        const content = document.getElementById("print-content").innerHTML;

        const fullContent = `
          <html xmlns:w="urn:schemas-microsoft-com:office:word" lang="en">
            <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <meta http-equiv="X-UA-Compatible" content="ie=edge">
            <style>
                body { margin:0; }
            </style>
            <title></title>
            </head>
            <body>
              <w:sectPr>
                <w:headerReference w:type="default" r:id="rId1"/>
              </w:sectPr>
              ${content}
            </body>
          </html>
        `;

        const options = {
            orientation: 'portrait',
            encoding: 'UTF-8'
        };

        const converted = docx.asBlob(fullContent, options);
        fileSaver.saveAs(converted, `Traitement_${codeSommaire}.docx`);
    };

    const pageStyle = `
    .page-footer, .page-footer-space {
        height: auto;
        display:block;
        margin-top:"100px";
      }
      
      .page-footer {
        position: absolute;
        bottom: 0;
        // display:block;
        width: 100%;
        border-top: 1px solid black;
        background: yellow; 
        opacity:0.3;
        
      }

      .page {
        page-break-after: always;
      }
      
      @page {
        margin-bottom: 40mm;
        size: portrait
    }
    `;


    useEffect(() => {
        fetchQuestionnaire();
    }, []);

    const fetchQuestionnaire = ( ) => {
        getListeQuestion()
            .then((res) => {
                const entities = res.result.filter((item) => item.Bog_GroupeQuestion.critereGroupe === "ENTITE");
                const auditeurs = res.result.filter((item) => item.Bog_GroupeQuestion.critereGroupe === "AUDITEUR");
                if (entities.length > 0) {
                    const libelleGroup = [...new Set(entities.map((item) => item.Bog_GroupeQuestion.libelle))];
                    const tab = libelleGroup.map((libelle) => ({
                        libelleGroupe: libelle,
                        questions: entities.filter((item) => item.Bog_GroupeQuestion.libelle === libelle),
                    }));

                    setQuestionsEntity(tab);
                }
                if(auditeurs){
                    const libelleGroup = [...new Set(auditeurs.map((item) => item.Bog_GroupeQuestion.libelle))];
                    const tab = libelleGroup.map((libelle) => ({
                        libelleGroupe: libelle,
                        questions: auditeurs.filter((item) => item.Bog_GroupeQuestion.libelle === libelle),
                    }));

                    setQuestionsAuditeur(tab)
                }
            })
            .catch(error => {
                console.error("Error fetching questionnaire:", error);
            });
    };

    useEffect(() => {
        getListeReponseMission(mission.id)
            .then((res) => {
                if(res.length > 0){
                    setListQuestionAdd(true)
                }

                let mainArraySetEntity = []
                let mainArraySetAuditeur = []

                const array2 = []
                const array1 = []
                if(questionsEntity.length > 0) {
                    questionsEntity.forEach((quest) =>{
                        mainArraySetEntity = new Set(quest.questions.map(question => question.id));
                        const arrayEntity = res.filter(element => mainArraySetEntity.has(element.question_id));
                        if (arrayEntity.length > 0) {
                            arrayEntity.forEach((item) => {
                                const element = {
                                    question_id: item.question_id,
                                    libelle: item.libelle,
                                    response: item.response,
                                    isChecked: item.response === 'POSITIF',
                                    defaultResponse: item.reponse_defaut === 'POSITIF',
                                    mesure_sauve: item.mesure_sauve,
                                    motifRequired: false,
                                    mesureRequired: false,
                                    motif: item.motif,
                                }
                                array1.push(element)
                            });
                        }
                    })
                    setSelectedElementsListEntity(array1);
                    // setIsModified(array1?.length > 0)
                }

                if(questionsAuditeur.length > 0) {
                    questionsAuditeur.forEach((quest) =>{
                        mainArraySetAuditeur = new Set(quest.questions.map(question => question.id));
                        const arrayAuditeur = res.filter(element => mainArraySetAuditeur.has(element.question_id));
                        if (arrayAuditeur.length > 0) {
                            arrayAuditeur.forEach((item) => {
                                const element = {
                                    question_id: item.question_id,
                                    libelle: item.libelle,
                                    response: item.response,
                                    isChecked: item.response === 'POSITIF',
                                    defaultResponse: item.reponse_defaut === 'POSITIF',
                                    motifRequired: false,
                                    mesure_sauve: item.mesure_sauve,
                                    mesureRequired: false,
                                    motif: item.motif,
                                }
                                array2.push(element)
                            });
                        }
                    })
                    setSelectedElementsListAudit(array2);
                    // setIsModified(array2?.length > 0)
                }

            })
    }, [mission, questionsEntity, questionsAuditeur]);

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            if (auth) {
                e.preventDefault();
                e.returnValue = ""; // required for chrome
                return true; // Return a truthy value
            }
            return null; // Allow navigation if no conditions met
        };

        // const handleBrowseAway = (e) => {
        //     e.preventDefault();
        //     return authId !== null;
        // };

        window.addEventListener("beforeunload", handleBeforeUnload);
        // window.addEventListener('popstate', handleBrowseAway);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload)
            // window.removeEventListener('popstate', handleBrowseAway);
        };
    }, [auth, navigate]);

    // useEffect(() => {
    //     if(authId !== null){
    //         const unblock = navigate((newLocation, { action }) => {
    //             if (authId !== null && action === "POP") {
    //                 const confirmLeave = window.confirm(
    //                     "Vous avez des modifications non sauvegardées, voulez-vous vraiment quitter cette page ?"
    //                 );
    //                 if (!confirmLeave) {
    //                     return false;
    //                 }
    //             }
    //         });
    //
    //         return () => unblock;
    //     }
    // }, [authId, navigate]);


    const handleNextStep = (e) => {
        e.preventDefault();
        if(!auth){
            openNotificationWithIcon()
            return setActiveStep(0);
        }
        else if(activeStep > 1 && !isFileAdd){
            setActiveStep(1)
        }
        else {
            setActiveStep((prevStep) => prevStep + 1);
        }
    };

    const handlePrevStep = (e) => {
        e.preventDefault();
        setActiveStep((prevStep) => prevStep - 1);
    };

    const isLastStep = () => {
        return activeStep === formSteps.length - 1;
    };

    const handleSubmit = async (e) => {
        e.preventDefault()

        setLoading(true);
        const selectedItems = [...selectedElementsListAudit, ...selectedElementsListEntity]

        const require = selectedItems.some((item) => item.motifRequired || item.mesureRequired);
        if(require){
            notification.warning({
                message: "Tous les champs obligatoires doivent être renseignés.",
            });
            setLoading(false);
            return
        }

        const questionnaires = selectedItems.map((item) => ({
            question_id: item.question_id,
            reponse: item.isChecked ? 'POSITIF' : 'NEGATIF',
            motif: item.motif,
            mesure_sauve: item.mesure_sauve
        }));

        const data = {
            intervention_id,
            date,
            heureEntree,
            tache_id,
            user_id,
            codeSommaire,
            questionnaires: questionnaires,
            exercice_id: mission.id,
            mesure_sauve: mesure_sauve,
            client_id: client.id
        };

        const {status, message} = listQuestionAdd === true ? await updateReponseQuestion(data) : await addReponseQuestion(data)
        if (status === 'success') {
            setLoading(false)
            setMesureSauve('')
            setSelectedElementsListAudit([])
            setSelectedElementsListEntity([])
            await swal(succesMessage, '', 'success')
            if (activeStep > 3) {
                prev();
                window.location.reload();
            }
        } else {
            setLoading(false)
            await swal(message, '', 'error')
        }
    }

    // const handleValidate = async (value) => {
    //     // setChargement(true)
    //     const data = {
    //         authId,
    //         intervention_id: value.intervention_id,
    //         tache_id: value.tache_id,
    //         codeSommaire,
    //         exercice_id: mission.id,
    //     };
    //
    //     const {status, message} = await ajouterUserIdentification(data);
    //     if (status === 'success') {
    //         await swal(succesMessage, '', 'success')
    //         setAuthId(null);
    //         prev();
    //     } else {
    //         // setChargement(false)
    //         await swal(message, '', 'error')
    //     }
    // }

    const handleAffectSubmit = async () => {
        setLoading(true);

        const data = {
            intervention_id,
            date,
            heureEntree,
            tache_id,
            user_id,
            codeSommaire,
            etat: etat,
            exercice_id: mission.id,
            client_id: client.id,
            associe_responsable,
            second_associer,
            motifs: selectedIds,
            observation_generale
        };

        const {status, message} = await addReponseAccepation(data)
        if (status === 'success') {
            setLoading(false)
            setSecondAssocier('');
            setAssocieResponsable('');
            await swal(succesMessage, '', 'success')
            setAuth(false);
            if (activeStep > 4) {
                prev()
            }
            setIsTaskSave(prevState => (prevState ?? []).map(item => item.activeTab));
        } else {
            setLoading(false)
            await swal(message, '', 'error')
        }
    }

    const formSteps = [
        {
            code: "ID",
            title: "IDENTIFICATIONS",
            icon: faIdCard,
            content: (
                <UserCycleIdentification
                    setDate={setDate}
                    dateDebut={date}
                    setTache_id={setTache_id}
                    setIntervention_id={setIntervention_id}
                    setHeureEntree={setHeureEntree}
                    setUser_id={setUser_id}
                    mission={mission}
                    prev={prev}
                    loading={loading}
                    codeSommaire={codeSommaire}
                    setIsAuthorized={setAuth}
                    isAuthorized={auth}
                    setActiveStep={setActiveStep}
                />
            ),
        },
        {
            code: "EB1.0",
            title: "QUESTIONNAIRES MANUSCRIT",
            icon: faFileCircleQuestion,
            content: (
                <FileUploadQuestionnaire
                    setIsFileAdd={setIsFileAdd}
                    isFileAdd={isFileAdd}
                    exercice={mission}
                    client={client}
                    codeSommaire={codeSommaire}
                />
            ),
        },
        {
            code: "EB1.1",
            title: "CRITERES RELATIF A L'ENTITÉ",
            icon: faBriefcase,
            content: (
                <ClientModalEntite
                    loading={loading}
                    selectedElementsList={selectedElementsListEntity}
                    setSelectedElementsList={setSelectedElementsListEntity}
                    questions={questionsEntity}
                    client={client}
                    mission={mission}
                    codeSommaire={codeSommaire}
                    acceptValid={acceptValid}
                    title="EB1. 1: Acceptation Mission - Critères relatifs à l’Entité"
                />
            ),
        },
        {
            code: "EB1.2",
            title: "CRITERES RELATIF A L'AUDITEUR",
            icon: faUserTag,
            content: (
                <ClientModalEntite
                    selectedElementsList={selectedElementsListAudit}
                    setSelectedElementsList={setSelectedElementsListAudit}
                    questions={questionsAuditeur}
                    client={client}
                    mission={mission}
                    codeSommaire={codeSommaire}
                    acceptValid={acceptValid}
                    title="EB1. 2: Acceptation Mission - Critères relatifs à l’Auditeur"
                />
            ),
        },
        {
            code: "EB1.3",
            title: "MESURES DE SAUVEGARDE",
            icon: faListCheck,
            content: (
                <ClientModalMesures
                    acceptValid={acceptValid}
                    mission={mission}
                    selectedElementsListAudit={selectedElementsListAudit}
                    selectedElementsListEntity={selectedElementsListEntity}
                    setSelectedElementsListAudit={setSelectedElementsListAudit}
                    setSelectedElementsListEntity={setSelectedElementsListEntity}
                />
            ),
        },
        modalId === 2 && {
            code: "EB1.4",
            title: "DECISION ET AFFECTATION DU DOSSIER",
            icon: faFileCircleCheck,
            content: (
                <ClientModalAffectation
                    mission={mission}
                    setObservation_generale={setObservation_generale}
                    observation_generale={observation_generale}
                    second_associer={second_associer}
                    setSecondAssocier={setSecondAssocier}
                    setAssocieResponsable={setAssocieResponsable}
                    associe_responsable={associe_responsable}
                    setEtat={setEtat}
                    affectValid={affectValid}
                    setSelectedIds={setSelectedIds}
                    selectedIds={selectedIds}
                />
            ),
        },
    ];

    const toggleMenu = (e) => {
        e.preventDefault()
        setIsToggleOpen(!isToggleOpen);
    };

    return (
        <>
            {contextHolder}
        <Fragment>
            <div className="d-flex justify-content-between pb-5">
                <PageChildLabel label={"EB1 - Acceptation de la mission"} />
                <div className="d-flex justify-content-between">
                    <div className="d-flex me-15 mb-4">
                        <Space>
                            <div className="d-flex ms-5">
                                <Tooltip title="Imprimer le cycle">
                                    <div className="d-flex flex-column align-items-center ">
                                        <button
                                            className="btn btn-sm btn-flex btn-secondary fw-bold fs-7"
                                            data-kt-menu-trigger="click"
                                            data-kt-menu-placement="bottom-end"
                                            onClick={toggleMenu}
                                        >
                                            <FontAwesomeIcon icon={faPrint} className="me-3" />
                                            Exporter
                                        </button>
                                        {isToggleOpen && (
                                            <>
                                                <div
                                                    id="kt_users_lists_views_export_menu"
                                                    className="menu menu-sub menu-sub-dropdown menu-option menu-column menu-rounded menu-gray-600 menu-state-bg-primary fw-bold fs-7 w-150px py-4 show"
                                                    data-kt-menu="true"
                                                    data-popper-placement="bottom-end"
                                                >
                                                    <div className="menu-item px-3 py-2 bg-hover-warning text-hover-white rounded-2">
                                                        <ReactToPrint
                                                            trigger={() => (
                                                                <button className="fw-bold fs-7 text-hover-white"
                                                                        data-kt-menu-trigger="click"
                                                                        data-kt-menu-placement="bottom-end"
                                                                        onClick={() => {}}
                                                                >
                                                                    <FontAwesomeIcon icon={faFilePdf} style={{fontSize: "16px"}} className="me-3" />
                                                                    PDF
                                                                </button>
                                                            )}
                                                            content={() => componentRef.current}
                                                            documentTitle=''
                                                            pageStyle={pageStyle}
                                                        />
                                                    </div>
                                                    <div className="menu-item px-3 py-2 bg-hover-warning text-hover-white rounded-2">
                                                        <button className="fw-bold fs-7 text-hover-white"
                                                                data-kt-menu-trigger="click"
                                                                data-kt-menu-placement="bottom-end"
                                                                onClick={(e) => {
                                                                    e.preventDefault()
                                                                    exportToWord()
                                                                }}
                                                        >
                                                            <FontAwesomeIcon icon={faFileWord} style={{fontSize: "16px"}} className="me-3" />
                                                            WORD
                                                        </button>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </Tooltip>
                            </div>
                        </Space>
                    </div>
                    <div className="d-flex ms-5">
                        <div>
                            <Tooltip title="Quitter le cycle" color="red">
                                <button
                                    className="btn btn-sm btn-danger me-3"
                                    onClick={() => prev()}
                                >
                                    Retour
                                </button>
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mx-5">
                <StepHeader>
                    <Steps
                        current={activeStep}
                        size="small"
                        responsive={true}
                        style={{ color: "green" }}
                    >
                        {formSteps.map((item, index,) => (
                            <Step
                                className="app-menu-option"
                                key={item.code}
                                title={
                                    <>
                                        <div className="d-flex align-center">
                                            <span className="app-menu-option-title me-3">{`${item.code || ""}`}</span>
                                            {!auth && index > 0 && <Tooltip title="Cycle verrouillé. Veuillez vous identifier" color="red">
                                                <div><FontAwesomeIcon icon={faLock}
                                                                      style={{color: 'red', fontSize: '12px'}}/></div>
                                            </Tooltip>}
                                        </div>
                                    </>
                                }
                                // description={item.title}
                                icon={<FontAwesomeIcon icon={item.icon} />}
                                onClick={() =>{
                                    if(!auth){
                                        openNotificationWithIcon()
                                        return setActiveStep(0);
                                    }
                                    else if(index > 1 && !isFileAdd){
                                        setActiveStep(1)
                                    }
                                    else {
                                        setActiveStep(index)
                                    }
                                }}
                            />
                        ))}
                    </Steps>
                </StepHeader>
            </div>
            <StepBody>{formSteps[activeStep].content}</StepBody>
            <br/>
            <div style={{ textAlign: "center" }}>
                {activeStep  >= 1 &&
                <Button
                    size="large"
                    onClick={handlePrevStep}
                    style={{marginRight: "20px"}}
                >
                    Précédent
                </Button>}
                {!isLastStep() && <Button
                    size="large"
                    onClick={handleNextStep}
                    style={{marginRight: "20px", backgroundColor: appTheme.colors.primary_blue, color: "white"}}
                >
                    Suivant
                </Button>}
                {activeStep > 1 && ( loading ? (
                    <Spinner ml={"10px"} />
                ) : (
                   modalId === 2 ?
                       (
                           ((mission.isCloture !== 1 || mission.isClotureProvisoire !== 1) || !affectValid) && <Button
                               size="large"
                               type="primary"
                               onClick={handleAffectSubmit}
                               style={{ marginRight: "20px" }}
                           >
                               Enregistrer
                           </Button>
                       )
                       :
                       (
                           (mission.isCloture !== 1 || mission.isClotureProvisoire !==1 || !affectValid) && <Button
                               size="large"
                               type="primary"
                               onClick={handleSubmit}
                               style={{ marginRight: "20px" }}
                           >
                               Enregistrer
                           </Button>
                       )
                ))}
                <Button
                    size="large"
                    danger
                    onClick={() => prev()}
                >
                    Annuler
                </Button>
            </div>
            <br/>
            <div className="d-none" >
                <div ref={componentRef} className="print-content" id="print-content" >
                    <PrintHeader title={`TRAITEMENTS ACCEPTATION DE LA MISSION `}/>
                    <AcceptationPrint
                        libelle={"Critères à prendre en consideration relatifs à l'entité"}
                        dataEntite={selectedElementsListEntity}
                        datasAuditeur={selectedElementsListAudit}
                        associe_responsable={associe_responsable}
                        second_associer={second_associer}
                    />
                </div>
            </div>
            {/*{isModalOpen && <IdentificationUser*/}
            {/*    setDate={setDate}*/}
            {/*    setTache_id={setTache_id}*/}
            {/*    tache_id={tache_id}*/}
            {/*    setIntervention_id={setIntervention_id}*/}
            {/*    intervention_id={intervention_id}*/}
            {/*    setUser_id={setUser_id}*/}
            {/*    mission={mission}*/}
            {/*    visible={isModalOpen}*/}
            {/*    prev={prev}*/}
            {/*    handleValider={handleModalOpen}*/}
            {/*    setHeureEntree={setHeureEntree}*/}
            {/*    loading={identLoading}*/}
            {/*/>}*/}
        </Fragment>
        </>
    );
};

export { AcceptationMission };
