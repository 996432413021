import {
  AuditOutlined,
  FileDoneOutlined,
  FolderOpenOutlined,
  FolderOutlined,
  ReconciliationOutlined,
} from "@ant-design/icons";
import { CircularProgressbar } from "react-circular-progressbar";
import React, { useEffect, useState } from "react";
import { getDashboardLeaDetails } from "../../data/client";
import { Form, Link, useNavigate } from "react-router-dom";
import { getClientAbonnement } from "../../data/bogClient";
import moment from "moment";
import { useSelector } from "react-redux";
import { Button, Col, Input, message, Modal, Row, Space, Table, Tag, Tooltip } from "antd";
import TableTitle from "../../components/common/TableTitle";
import { faEye, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormik } from "formik";
import * as Yup from "yup";

const DashBoardPage = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const [abonnement, setAbonnement] = useState(null);
  const [exercices, setExercices] = useState([]);
  const [percentage, setPercentage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [dashboard, setDashboard] = useState(null);
  const isAdmin = user?.libelleProfil === "Admin";
  const [modalAdd, setModalAdd] = useState(false);

  useEffect(() => {
    setLoading(true);
    getDashboardLeaDetails()
      .then((res) => {
        if (res) {
          setDashboard(res);
          setExercices(res?.exercicessUser);
        }
      })
      .catch((error) => {
        console.error(
          "Erreur lors de la récupération des données du dashboard :",
          error
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (user?.client_id) {
      setLoading(true);
      getClientAbonnement(user?.client_id)
        .then((res) => {
          if (res.result) {
            setAbonnement(res.result);

            const debutAbonnement = moment(res.result.date_debut_abon);
            const finAbonnement = moment(res.result.date_fin_abon);
            const pourcentage = Math.max(
              0,
              Math.min(
                100,
                ((moment() - debutAbonnement) /
                  (finAbonnement - debutAbonnement)) *
                  100
              )
            );

            setPercentage(parseInt(pourcentage));
          }
        })
        .catch((error) => {
          console.error(
            "Erreur lors de la récupération de l'abonnement du client :",
            error
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [user?.client_id]);

  const handleModalAdd = () => {
    setLoading(true);
    setTimeout(() => {
      setModalAdd(prev => !prev);
      setLoading(false);
    }, 1000);
  }

  const dataColumns = [
    {
      title: <TableTitle>#</TableTitle>,
      width: 50,
      render: (value, record, index) => {
        return <>{index + 1}</>;
      },
    },
    {
      title: <TableTitle>Exercice</TableTitle>,
      dataIndex: "exercice",
      key: "exercice",
      render: (value, record, index) => {
        return (
          <>
            <div className="d-flex align-items-center">
              <div className="symbol symbol-45px me-5">
                <FolderOpenOutlined
                  style={{
                    fontSize: "3rem",
                    color: "rgb(250, 152, 28)",
                  }}
                />
              </div>
              <div className="d-flex justify-content-start flex-column">
                <Link
                  className="text-gray-800 text-hover-primary"
                  to={`/clients/mission/exploitation/${record.id}`}
                  state={{
                    record: record?.client,
                    mission: record.mission,
                  }}
                >
                  <span className="text-dark fw-bolder text-hover-primary fs-6">
                    {record.mission_name || ""} - {record.mission_annee || ""}
                  </span>
                  <span className="text-muted fw-bold text-muted d-block fs-9 text-uppercase">
                    <Tag>{record.sigle_client}</Tag> | {record.status_client}
                  </span>
                  <span className="text-black fw-bold d-block fs-7 text-uppercase">
                    {record.secteur_client}
                  </span>
                </Link>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: <TableTitle>Manager</TableTitle>,
      dataIndex: "manager",
      key: "manager",
      render: (value, record, index) => {
        return (
          <>
            <Space>
              <div className="fs-2 text-warning pe-2">
                <FontAwesomeIcon icon={faUser} />
              </div>
              <span className="text-dark fw-bolder text-hover-primary d-block fs-5 text-uppercase">
                {record.nom_manager
                  ? `${record.nom_manager} ${
                      record.prenom_manager && record.prenom_manager
                    }`
                  : ""}
              </span>
            </Space>
          </>
        );
      },
    },
    {
      title: <TableTitle>Status</TableTitle>,
      dataIndex: "status",
      key: "status",
      render: (value, record, index) => {
        return (
            <>
                {!record.isCloture ?
                    record.isLettreMission === 0 && record.etat === 1 ?
                        <Tag color="yellow">En attente</Tag> :
                        <Tag color="blue">En cours</Tag>
                    :
                    <Tag color="green">Clôturé</Tag>}
            </>
        );
      },
    },
    {
      title: <TableTitle>Progression</TableTitle>,
      dataIndex: "progression",
      key: "progression",
      render: (value, record, index) => {
        return (
          <div className="d-flex flex-column w-100 me-2">
            <div className="d-flex flex-stack mb-2">
              <span className="text-muted me-2 fs-7 fw-bold">
                {record.isCloture ? 100 : record.progression}
              </span>
            </div>
            <div className="progress h-6px w-100">
              <div
                className={`progress-bar bg-${record.color}`}
                role="progressbar"
                style={{ width: `${record.isCloture ? 100 : record.progression}%` }}
                aria-valuenow="50"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>
        );
      },
    },
    {
      title: <TableTitle>Actions</TableTitle>,
      dataIndex: "actions",
      align: "right",
      width: 100,
      key: "actions",
      render: (value, record, index) => {
        return (
          <Space>
            <Tooltip title="Consulter le sommaire">
              <Button
                type="primary"
                icon={<FontAwesomeIcon icon={faEye} />}
                onClick={() => {
                  navigate(`/clients/mission/exploitation/${record.id}`, {
                    state: {
                      record: record?.client,
                      mission: record.mission,
                    },
                  });
                }}
              />
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <div
        className="content d-flex flex-column flex-column-fluid"
        id="kt_content"
      >
        <div id="kt_content_container" className="container-xxl">
          <div className="row g-5 g-xl-12">
            <div className="col-xl-3">
              <div className="card bg-warning hoverable card-xl-stretch mb-xl-8">
                <div className="card-body">
                  <FileDoneOutlined
                    style={{ fontSize: "4rem", color: "#fff" }}
                  />

                  <div className="text-white fw-bolder fs-2 mb-2 mt-5">
                    Exercices Terminés
                  </div>
                  <div className="fw-bold text-white">{`${
                    isAdmin ? dashboard?.nbrExercicesClotures : dashboard?.nbrExercicesCloturesUser || 0
                  } Exercices Terminés`}</div>
                </div>
              </div>
            </div>
            <div className="col-xl-3">
              <div className="card bg-danger hoverable card-xl-stretch mb-xl-8">
                <div className="card-body">
                  <ReconciliationOutlined
                    style={{ fontSize: "4rem", color: "#fff" }}
                  />
                  <div className="text-white fw-bolder fs-2 mb-2 mt-5">
                    Exercices en cours
                  </div>
                  <div className="fw-bold text-white">{`${
                    isAdmin ? dashboard?.nbrExercicesCours : dashboard?.exercicesCoursUser?.length || 0
                  } Exercices en cours`}</div>
                </div>
              </div>
            </div>
            <div className="col-xl-3">
              <Link
                to="/clients/liste"
                className="card bg-primary hoverable card-xl-stretch mb-xl-8"
              >
                <div className="card-body">
                  <AuditOutlined style={{ fontSize: "4rem", color: "#fff" }} />

                  <div className="text-white fw-bolder fs-2 mb-2 mt-5">
                    Mes Exercices
                  </div>
                  <div className="fw-bold text-white">{`${
                    isAdmin ? dashboard?.nbrExercices : dashboard?.exercicessUser?.length || 0
                  } Exercices`}</div>
                </div>
              </Link>
            </div>
            <div className="col-xl-3">
              <div className="card bg-success hoverable card-xl-stretch mb-5 mb-xl-8">
                <div className="card-body">
                  <FolderOutlined style={{ fontSize: "4rem", color: "#fff" }} />

                  <div className="text-white fw-bolder fs-2 mb-2 mt-5">
                    Mes Dossiers
                  </div>
                  <div className="fw-bold text-white">{`${
                    isAdmin ? dashboard?.nbrClientsLeas : dashboard?.dossiersUserConnecte || 0
                  } Dossiers`}</div>
                </div>
              </div>
            </div>
          </div>

          <div className="row gy-5 g-xl-8">
            <Row gutter={[16, 16]}>
              <Col xs={24} md={24} lg={8} xl={8}>
                {isAdmin && (
                  <div className="card card-xl-stretch">
                    <div className="card-header border-0 py-5">
                      <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bolder fs-3 mb-1">
                          Abonnement
                        </span>
                        <span className="text-muted fw-bold fs-7">
                          Progression de votre abonnement
                        </span>
                      </h3>
                      <div className="card-toolbar" style={{ margin: "auto" }}>
                        <CircularProgressbar
                          value={percentage}
                          text={`${percentage}%`}
                        />
                      </div>
                    </div>

                    {isAdmin && percentage > 80 && (
                      <div className="card-body d-flex flex-column">
                        <div className="pt-5">
                          <p className="text-center fs-6 pb-5">
                            <span className="badge badge-light-danger fs-8">
                              Notes:
                            </span>
                            Votre abonnement sera bientôt épuisé.
                            <br />
                            Cliquez ici pour faire une demande de réabonnement.
                          </p>
                          <div
                            className="btn btn-primary w-100 py-3 cursor-pointer"
                            onClick={handleModalAdd}
                          >
                            Demande de réabonnement
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </Col>
              {exercices && exercices.length > 0 && <Col xs={24} md={24} lg={16} xl={16}>
                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                  <div className="card-header border-0 pt-5">
                    <h2 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bolder fs-2 mb-1">
                        Dossiers en cours
                      </span>
                      <span className="text-muted mt-1 fw-bold fs-6">
                        Etat des dossiers en cours
                      </span>
                    </h2>
                  </div>
                  <div className="card-body py-3">
                    <Table
                      columns={dataColumns}
                      dataSource={exercices}
                      pagination={{ pageSize: 5, showSizeChanger: true }}
                      size="small"
                    />
                  </div>
                </div>
              </Col>}
            </Row>
          </div>
        </div>
        {/* <ModalAddAbonnement open={modalAdd} setOpen={setModalAdd} /> */}
      </div>
    </>
  );
};

const ModalAddAbonnement = ({ open, setOpen, data, isEdit, getData }) => {
    const [loading, setLoading] = useState(false);
  
    const formik = useFormik({
      initialValues: {
        libelle_prestation: data?.libelle_prestation || "",
        code_prestation: data?.code_prestation || "",
        description_prestation: data?.description_prestation || "",
        type_prestation: data?.type_prestation || ""
      },
      validationSchema: Yup.object({
        libelle_prestation: Yup.string().required("Le libellé est obligatoire"),
        code_prestation: Yup.string().required("Le code est obligatoire")
      }),
      enableReinitialize: true,
      onSubmit: async (values) => {
        try {
          setLoading(true);
        //   const endpoint = isEdit
        //     ? ENDPOINTS.MODIFIER_CATEGORIE_PRESTATION
        //     : ENDPOINTS.AJOUTER_CATEGORIE_PRESTATION;
        //   const payload = isEdit ? { ...values, id: data.id } : values;
  
        //   const res = await RqAxios.post(endpoint, payload);
  
        //   if (res.data.statut === "succes") {
        //     message.success(
        //       `Catégorie de prestation ${isEdit ? "modifiée" : "ajoutée"} avec succès`
        //     );
        //     getData();
        //     handleCancel();
        //   } else {
        //     message.error(res.data.message);
        //   }
        } catch (error) {
          message.error("Une erreur s'est produite");
        } finally {
          setLoading(false);
        }
      },
    });
  
    const handleCancel = () => {
      setOpen(false);
    //   formik.resetForm();
    };
  
    return (
      <Modal
        title={`${isEdit ? "Modifier" : "Ajouter"} une catégorie de prestation`}
        open={open}
        onOk={formik.handleSubmit}
        onCancel={handleCancel}
        confirmLoading={loading}
        okText={isEdit ? "Modifier" : "Ajouter"}
        cancelText="Annuler"
        width={600}
      >
        <Form layout="vertical">
          <Form.Item
            label={<>Code <span style={{ color: 'red' }}>*</span></>}
            validateStatus={
              formik.touched.code_prestation && formik.errors.code_prestation
                ? "error"
                : ""
            }
            help={
              formik.touched.code_prestation && formik.errors.code_prestation
            }
          >
            <Input
              placeholder="Code"
              name="code_prestation"
              value={formik.values.code_prestation}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
  
          <Form.Item
            label={<>Libellé <span style={{ color: 'red' }}>*</span></>}
            validateStatus={
              formik.touched.libelle_prestation &&
              formik.errors.libelle_prestation
                ? "error"
                : ""
            }
            help={
              formik.touched.libelle_prestation &&
              formik.errors.libelle_prestation
            }
          >
            <Input
              placeholder="Libellé"
              name="libelle_prestation"
              value={formik.values.libelle_prestation}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
  
          <Form.Item
            label="Description"
          >
            <Input.TextArea
              placeholder="Description"
              name="description_prestation"
              value={formik.values.description_prestation}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
  
          <Form.Item
            label="Type de prestation"
          >
            <Input
              placeholder="Type de prestation"
              name="type_prestation"
              value={formik.values.type_prestation}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Form>
      </Modal>
    );
  };

export default DashBoardPage;
