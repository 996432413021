import React from "react";
import {
    DeleteFilled,
} from "@ant-design/icons";
import {uploadExerciceFile} from "../../../../data/file";
import swal from "sweetalert";
import {ModalTitle} from "../../../modals/modals.components";
import {Form, Formik} from "formik";
import {Button, Col, Input, Row, Select} from "antd";
import FormLabel from "../../../common/Form/FormLabel";
import FormError from "../../../common/Form/FormError";
import ModalAlert from "../../../modals/Modal";
import * as Yup from "yup";
import {getFilenameWithoutExtension, getIconForFileType} from "../../../layout/menu/CustomMenu";
import {dossierSommaires} from "../../modals/missionSteps/EA/DPSommaires";

const FileUploader = ({
      code,
      data,
      chargement,
        fetchFile,
      visible,
      handleVisibility,
    }) => {

    const handleUploadAll = async (value) => {
        const fileSizeLimit = 10 * 1024 * 1024;
        for (const file of value.selectedFiles) {
            if (file.size <= fileSizeLimit) {
                const extension = file.name.split(".").pop().toLowerCase();

                const formData = new FormData();
                formData.append("file", file);
                formData.append("libelle", value.libelle);
                formData.append("description", value.description);
                formData.append("client_id", value.client_id);
                formData.append("code", value.code);
                formData.append("reference", value.reference);
                formData.append("notes", value.notes);
                formData.append('size', file.size);
                formData.append('extension', extension);

                try {
                    const response = await uploadExerciceFile(formData);
                    if (response.status === "success") {
                        await swal('Opération effectuée avec succès', '', 'success')
                        fetchFile(data.id, code);
                        handleVisibility()
                    } else {
                        await swal(response.message, '', 'error')
                    }
                } catch (error) {
                    console.error("Error uploading file:", error);
                    console.log("Failed to upload file: " + file.name);
                }
            } else {
                console.log("File size exceeds the limit: " + file.name);
            }
        }
    };

    const dataValidationScheme = Yup.object({
        client_id: Yup.number()
            .required("Veuillez sélectionner le client")
            // .nullable("Le client est obligatoire")
            .typeError("Le client est obligatoire"),
            reference: Yup.string().required("La reference est obligatoire"),
        libelle: Yup.string().required("Le libellé est obligatoire"),
        code: Yup.string().required("Le code sommaire du DP est obligatoire"),
    });
    
    return (
        <>
            <ModalAlert
                closeModal={handleVisibility}
                show={visible}
                title={<ModalTitle> Joindre un fichier au sommaire DP {code || ''} de {`${data.sigle && data.sigle}`}</ModalTitle>}
                footer={[]}
                modalStyles={{ top: 30 }}
                width={800}
            >
                <Formik
                    initialValues={{
                        selectedFiles: [],
                        libelle: '',
                        description: "",
                        code: code || "",
                        reference: `${code}-XXX` || "",
                        notes: "",
                        client_id: (data && data.id) || ""
                    }}
                    onSubmit={(values) => handleUploadAll(values)}
                    validationSchema={dataValidationScheme}
                >
                    {({ values, handleSubmit, setFieldValue, touched, errors }) => (
                        <Form>
                            <Row gutter={[16, 16]}>

                                <Col span={24}>
                                    <FormLabel label="Fichier" required />
                                    <div className='row fv-row mb-7 fv-plugins-icon-container'>
                                        <div className='d-flex align-center'>
                                            <div className='me-5'>
                                                <div className='w-500px d-flex align-items-center mb-2 mt-2 px-10'>
                                                    <input
                                                        type='file'
                                                        className='form-control me-10'
                                                        // accept=".xlsx, .csv, .xls"
                                                        onChange={(value) => {
                                                            const files = value.target.files;

                                                            const fileSizeLimit = 10 * 1024 * 1024;

                                                            const validFiles = Array.from(files).filter(
                                                                (file) => file.size <= fileSizeLimit
                                                            );
                                                            setFieldValue("selectedFiles", [...values.selectedFiles, ...validFiles]);
                                                            setFieldValue("libelle", getFilenameWithoutExtension(validFiles[0].name));
                                                        }}
                                                        required={true}
                                                    />
                                                </div>
                                                <span className='form-text fs-6 text-muted'>
                                                Taille maximale de fichier 10MB.
                                              </span>
                                            </div>
                                            <div className='pt-2'>
                                                <button
                                                    className='dropzone-remove-all btn btn-sm btn-light-primary'
                                                    onClick={() => {
                                                        setFieldValue("selectedFiles", [])
                                                    }}
                                                >
                                                    <DeleteFilled style={{color: 'red', fontSize: '15px'}}/>
                                                </button>
                                            </div>
                                        </div>

                                        <div className='dropzone-items wm-200px'>
                                            {/* Show the list of selected files */}
                                            {values.selectedFiles.map((file, index) => (
                                                <div key={index} className='m-6'>
                                                    {getIconForFileType(file.name)} {file.name}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    {touched.selectedFiles && errors.selectedFiles && (
                                        <FormError fieldName="selectedFiles" />
                                    )}
                                </Col>
                                <Col span={12}>
                                    <FormLabel label="Sommaire DP" required />
                                    <Select
                                        showSearch
                                        placeholder="Sélectionnez..."
                                        optionFilterProp="label"
                                        filterOption={(input, option) =>
                                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        options={dossierSommaires && dossierSommaires.map((sommaire) => ({
                                            value: sommaire.code,
                                            label: sommaire.libelle.toUpperCase()
                                        }))}
                                        value={values.code ?? null}
                                        onChange={(value) =>{
                                            setFieldValue("code", value)
                                            setFieldValue("reference", `${value}-XXX` || "")
                                        }}
                                        size="large"
                                        style={{ width: "100%" }}
                                    />
                                    {touched.code && errors.code && (
                                        <FormError fieldName="code" />
                                    )}
                                </Col>
                                <Col span={12}>
                                    <FormLabel label="Reférence" required />
                                    <Input
                                        size="large"
                                        placeholder="Saisir la reférence"
                                        name="reference"
                                        value={values.reference}
                                        onChange={(e) =>
                                            setFieldValue("reference", e.target.value)
                                        }
                                    />
                                    {touched.reference && errors.reference && values.reference?.includes("XX") && (
                                        <FormError fieldName="reference" />
                                    )}

                                </Col>
                                <Col span={24}>
                                    <FormLabel label="Nom du fichier" required />
                                    <Input
                                        size="large"
                                        placeholder="Le nom du fichier"
                                        name="libelle"
                                        value={values.libelle}
                                        onChange={(e) =>
                                            setFieldValue("libelle", e.target.value)
                                        }
                                    />
                                    {touched.libelle && errors.libelle && (
                                        <FormError fieldName="libelle" />
                                    )}
                                </Col>
                                <Col span={24}>
                                    <FormLabel label="Note" />
                                    <Input.TextArea
                                        size="large"
                                        placeholder="Message"
                                        name="notes"
                                        value={values.notes}
                                        onChange={(e) =>
                                            setFieldValue("notes", e.target.value)
                                        }
                                        showCount
                                        rows={4}
                                    />
                                    {touched.notes && errors.notes && (
                                        <FormError fieldName="notes" />
                                    )}
                                </Col>
                                <Col span={24}>
                                    <Button
                                        type="primary"
                                        block
                                        size="large"
                                        loading={chargement}
                                        disabled={chargement}
                                        onClick={handleSubmit}
                                    >
                                        {!chargement && <span className='indicator-label'>Enregistrer</span>}
                                        {chargement && (
                                            <span className="indicator-progress text-white">
                                            Veuillez patienter...
                                            <span className="spinner-border spinner-border-sm align-middle ms-2 text-white"></span>
                                        </span>
                                        )}
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </ModalAlert>
        </>
    );
};

export default FileUploader;
